import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn, signInStudent, closeNoAccess } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import firebase from '../../config/fbConfig'
import Footer from "../../components/ui/navbar/Footer"
import { CertBlock } from "./CertBlock";

class SignIn extends Component {
    state = {
        email: "",
        password: "",
        classCode: "",
        classPassword: "",
        isTeacher: false,
        verifyAge: false,
        verifyPolicy: false,
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.email === "") { return }
        if (this.state.email.indexOf("@") >= 0) {
            this.props.signIn(this.state);
        } else {
            const credentials = {
                classCode: this.state.email,
                classPassword: this.state.password
            }
            this.props.signInStudent(credentials)
        }
    }
    handleSubmitStudent = (e) => {
        e.preventDefault();
        const credentials = {
            classCode: this.state.classCode,
            classPassword: this.state.classPassword
        }
        this.props.signInStudent(credentials);
    }
    resetPassword = (e) => {
        e.preventDefault();
        const auth = firebase.auth();
        const emailAddress = this.state.email;
        auth.sendPasswordResetEmail(emailAddress).then(function () {
            alert("Password Reset email sent to " + emailAddress)
        }).catch(function (error) {
            alert("Unable to send Password Reset email to " + emailAddress + ". Please verify your email address and try again.")
        });
    }
    clickedCloseNoAccess = () => {
        this.props.closeNoAccess()
    }
    clickedTeacher = (e) => {
        e.preventDefault();
        this.setState({
            isTeacher: true,
            email: "",
            password: "",
            classCode: "",
            classPassword: ""
        })
    }
    clickedStudent = (e) => {
        e.preventDefault();
        this.setState({
            isTeacher: false,
            email: "",
            password: "",
            classCode: "",
            classPassword: ""
        })
    }

    clickedVerify = whichPolicy => e => {
        e.preventDefault()
        if (whichPolicy === "age") {
            this.setState({
                verifyAge: !this.state.verifyAge
            })
        } else {
            this.setState({
                verifyPolicy: !this.state.verifyPolicy
            })
        }
    }

    getNoAccessDisplay = () => {
        return (
            <div className="ajs-modal">
                <div className="modalcontent-myclassrooms">
                    <div>Due to website changes, your account has been temporarily disabled. Please contact Brooke Stephens for help.</div>
                    <button className="btn-oneclassroom btnnewclass-myclassrooms" onClick={this.clickedCloseNoAccess} >
                        <span>Close</span>
                    </button>
                </div>
            </div>
        )
    }


    render() {
        const { authError } = this.props;
        const uidStudent = this.props.state.auth.uidStudent
        const uidAdult = this.props.state.auth.uid
        const authFB = this.props.state.firebase.auth

        let noAccessDisplay = null

        if (this.props.state.auth.showNoAccess) {
            noAccessDisplay = this.getNoAccessDisplay()
        } else if (!authFB.isEmpty) {
            if (uidStudent || uidAdult) {
                console.log("HERE")
                return <Redirect to='/' />
            }
        }

        const stylesActiveButton = {
            backgroundColor: "#4a89dc",
            opacity: "1.0",
        }

        const stylesInactiveButton = {
            backgroundColor: "#626f7c",
            opacity: "0.5",
        }

        let verifyAgeImg = "../../../../../img/boxUnchecked.svg"
        let verifyPolicyImg = "../../../../../img/boxUnchecked.svg"
        if (this.state.verifyAge) {
            verifyAgeImg = "../../../../../img/boxChecked.svg"
        }
        if (this.state.verifyPolicy) {
            verifyPolicyImg = "../../../../../img/boxChecked.svg"
        }

        const signInTeacher = (
            <div className="signinform-container">
                <form className="login-form" onSubmit={this.handleSubmit} >
                    <h2>TEACHER Sign In</h2>
                    <div className="group">
                        <input
                            className="login-input"
                            type="text"
                            id="email"
                            placeholder="TEACHER EMAIL"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="email">Email</label>
                    </div>
                    <div className="group">
                        <input
                            className="login-input"
                            type="password"
                            id="password"
                            placeholder="TEACHER PASSWORD"
                            value={this.state.password}
                            onChange={this.handleChange}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="password">Password</label>
                    </div>
                    <button className="button buttonBlue">Login</button>
                    <div className="red-text center">
                        {authError ? <p>{authError}</p> : null}
                    </div>
                    <br />
                    <div className="pw-reset">
                        <a href="#!" onClick={this.resetPassword}>
                            Reset Password
                        </a>
                    </div>
                </form>
            </div>
        )

        const signInStudent = (
            <div className="signinform-container">
                <form className="login-form" onSubmit={this.handleSubmitStudent} >
                    <h2>STUDENT Sign In</h2>
                    <div className="group">
                        <input
                            className="login-input"
                            type="text"
                            id="classCode"
                            placeholder="CLASS CODE"
                            onChange={this.handleChange}
                            value={this.state.classCode}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="classCode">Class Code</label>
                    </div>
                    <div className="group">
                        <input
                            className="login-input"
                            type="password"
                            id="classPassword"
                            placeholder="STUDENT PASSWORD"
                            onChange={this.handleChange}
                            value={this.state.classPassword}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="classPassword">Password</label>
                    </div>
                    <button className="button buttonBlue">Login</button>
                    <div className="red-text center">
                        {authError ? <p>{authError}</p> : null}
                    </div>
                </form>
            </div>
        )

        const signInForm = this.state.isTeacher ?
            signInTeacher :
            signInStudent

        return (
            <div>
                {noAccessDisplay}
                <div className="signinpage-container">
                    <div className="login-text">
                        Welcome to Empowered Parents online learning platform.<br />We provide a variety of curricula to our members.<br /><a href="https://storylabs.online/empowered-parents">Click here</a> to learn more.
                    </div>
                    <div className="btn-container" >
                        <button
                            className="mbtn"
                            onClick={this.clickedTeacher}
                            style={this.state.isTeacher ? stylesActiveButton : stylesInactiveButton}
                        >
                            Teacher
                        </button>
                        <button
                            className="mbtn"
                            onClick={this.clickedStudent}
                            style={this.state.isTeacher ? stylesInactiveButton : stylesActiveButton}
                        >
                            Student/Parent
                        </button>
                    </div>

                    {signInForm}

                    <div className="verification-container" style={{ display: "none" }}>
                        <div className="verification-item1">
                            <div className="checkimage1" key="verifyAge" onClick={this.clickedVerify("age")}>
                                <img src={verifyAgeImg} alt="" />
                            </div>
                            <div className="verify-text1">
                                I am 13 years of age or older
                        </div>
                        </div>
                        <div className="signin-space"></div>
                        <div className="verification-item2">
                            <div className="checkimage2" key="verifyPolicy" onClick={this.clickedVerify("policy")}>
                                <img src={verifyPolicyImg} alt="" />
                            </div>
                            <div className="verify-text2">
                                I agree to the Terms & Conditions, Privacy Poicy, and Copyright Policy
                        </div>
                        </div>
                    </div>

                    <CertBlock />

                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds)),
        signInStudent: (creds) => dispatch(signInStudent(creds)),
        closeNoAccess: () => dispatch(closeNoAccess())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
