import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getBooks, updateDays } from '../adminPages/actionsAdmin'

class UserOrders extends Component {
    state = {
        daysPurchased: {
            // [orderId]: days
        },
        typingTimeout: 0
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        const prevOrders = prevProps.state.adminData ?
            prevProps.state.adminData.orders ?
                prevProps.state.adminData.orders :
                null :
            null
        if (prevOrders === null) {
            const newOrders =
                this.props.state.adminData.orders ?
                    this.props.state.adminData.orders :
                    null
            if (newOrders !== null) {
                const daysPurchased = {}
                newOrders.forEach((order) => {
                    daysPurchased[order.orderId] = order.daysPurchased ? (order.daysPurchased).toString() : ""
                })
                this.setState({ daysPurchased })
            }
        }
        else if (prevProps.state.adminData.userId !== this.props.state.adminData.userId) {
            const newOrders =
                this.props.state.adminData.orders ?
                    this.props.state.adminData.orders :
                    null
            const daysPurchased = {}
            newOrders.forEach((order) => {
                daysPurchased[order.orderId] = order.daysPurchased ? (order.daysPurchased).toString() : ""
            })
            this.setState({ daysPurchased })
        }
        else if (prevOrders.length !== this.props.state.adminData.orders.length) {
            const daysPurchased = {}
            this.props.state.adminData.orders.forEach((order) => {
                daysPurchased[order.orderId] = order.daysPurchased ? (order.daysPurchased).toString() : ""
            })
            this.setState({ daysPurchased })
        }
    }



    // Actions
    daysChanged = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        let daysPurchased = e.target.value
        // if ( daysPurchased === "" ) { daysPurcha /sed = "0" }
        const isNumber = /^\d+$/.test(daysPurchased)
        if (!isNumber && daysPurchased !== "") { return }

        

        const orderId = e.target.id

        this.setState({
            daysPurchased: {
                ...this.state.daysPurchased,
                [orderId]: daysPurchased
            },
            typingTimeout: setTimeout(() => {
                this.props.updateDays(orderId, daysPurchased)
            }, 400)
        })

    }

    // Render functions
    getOrderRows = () => {

        const orderRows = this.props.state.adminData.orders.map( (order) => {
            const days = this.state.daysPurchased[order.orderId] ? this.state.daysPurchased[order.orderId] : ""
                // console.log("VALUES")
                    // console.log(daysPurchased)
                // console.log(order.daysPurchased)
            const orderDays = order.daysPurchased ? (order.daysPurchased).toString() : ""
            let styleDays = "input-myclassrooms input-uploadepub ao-days"
            if (days !== orderDays) {
                styleDays = "input-myclassrooms input-uploadepub input-mcnew"
            }

            let styleExpiration = "col-10-body tdlastactivity-oneclassroom"
            if (order.isExpired) {
                styleExpiration = "col-10-body tdlastactivity-oneclassroom ao-isexpired"
            }


            return (
                <tr key={order.orderId}>
                    <td className="col-35-body tdlastactivity-oneclassroom">
                        {order.book.title}
                    </td>
                    <td className="col-20-body tdlastactivity-oneclassroom">
                        {order.book.book_author}
                    </td>
                    <td className="col-15-body tdlastactivity-oneclassroom ao-truncate">
                        {order.howOrdered}
                    </td>
                    <td className="col-10-body tdlastactivity-oneclassroom">
                        {order.createDate}
                    </td>
                    <td className="col-10-body">
                        <input
                            className={styleDays}
                            type="text"
                            id={order.orderId}
                            onChange={this.daysChanged}
                            value={days}
                        />
                    </td>
                    <td className={styleExpiration}>
                        {order.expirationDate}
                    </td>
                </tr>
            )
        });

        return orderRows

    }

    render(){
        if ( !this.props.state.adminData.orders ) return null
        return (
            <div>
                <div className="ao-orderstitle">
                    <span>Orders</span>
                    <span
                        className="ao-addorder"
                        onClick={this.props.clickedAddOrder}
                    >
                        Add Order
                    </span>
                </div>
                <div className="ao-tableWrap" >
                    <table className="table-oneclassroom">
                        <thead>
                            <tr>
                                <th className="col-35-header" >
                                    <span className="colhead-adminusers" > Book </span>
                                </th>
                                <th className="col-20-header" >
                                    <span className="colhead-adminusers" > Author </span>
                                </th>
                                <th className="col-15-header" >
                                    <span className="colhead-adminusers" > How </span>
                                </th>
                                <th className="col-10-header" >
                                    <span className="colhead-adminusers" > When </span>
                                </th>
                                <th className="col-10-header" >
                                    <span className="colhead-adminusers" > Days </span>
                                </th>
                                <th className="col-10-header" >
                                    <span className="colhead-adminusers" > Exp </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.getOrderRows()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => {    
    return {
        state,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBooks: () =>  dispatch(getBooks()),
        updateDays: (orderId, daysPurchased) => dispatch(updateDays(orderId, daysPurchased))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders)

