import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { signUp } from '../../store/actions/authActions'
import Footer from "../../components/ui/navbar/Footer"
import { CertBlock } from "./CertBlock";

class SignUp extends Component {
    state = {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        verifyAge: false,
        verifyPolicy: false,
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const allowLogin = this.state.verifyAge && this.state.verifyPolicy
        if (allowLogin) {
            this.props.signUp(this.state);
        } else {
            alert("Check box to agree to policies")
        }
    }
    clickedVerify = whichPolicy => e => {
        e.preventDefault()
        if (whichPolicy === "age") {
            this.setState({
                verifyAge: !this.state.verifyAge
            })
        } else {
            this.setState({
                verifyPolicy: !this.state.verifyPolicy
            })
        }
    }
    render() {
        // console.log("RENDER SIGNUP!!!!!!")
        const { auth, authError } = this.props;
        // if (auth.uid) return <Redirect to='/' />  //Route guard (Lesson 27)
        const uidStudent = this.props.state.auth.uidStudent
        const uidAdult = this.props.state.auth.uid
        if (uidStudent || uidAdult) return <Redirect to='/' />  //Route guard (Lesson 27)



        let verifyAgeImg = "../../../../../img/boxUnchecked.svg"
        let verifyPolicyImg = "../../../../../img/boxUnchecked.svg"
        if (this.state.verifyAge) {
            verifyAgeImg = "../../../../../img/boxChecked.svg"
        }
        if (this.state.verifyPolicy) {
            verifyPolicyImg = "../../../../../img/boxChecked.svg"
        }

        return (

            <div className="signinpage-container">
                <form className="login-form" onSubmit={this.handleSubmit} >
                    <h2>Teacher Sign Up</h2>
                    <div className="group">
                        <input className="login-input" type="text" id="firstName" onChange={this.handleChange} />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="firstName">First Name</label>
                    </div>
                    <div className="group">
                        <input className="login-input" type="text" id="lastName" onChange={this.handleChange} />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="lastName">Last Name</label>
                    </div>
                    <div className="group">
                        <input className="login-input" type="email" id="email" onChange={this.handleChange} />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="email">&nbsp;Email</label>
                    </div>
                    <div className="group">
                        <input className="login-input" type="password" id="password" onChange={this.handleChange} />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                        <label className="login-label" htmlFor="password">Password</label>
                    </div>

                    <button className="button buttonBlue">Signup</button>
                    {/* <div className="ripples buttonRipples"><span className="ripplesCircle"></span></div> */}
                    <div className="red-text center">
                        {authError ? <p>{authError}</p> : null} {/*Lesson 23/40*/}
                    </div>
                </form>

                <div className="verification-overall"> 
                    <div className="verification-container">
                        <div className="verification-item1">
                            <div className="checkimage1" key="verifyAge" onClick={this.clickedVerify("age")}>
                                <img src={verifyAgeImg} alt="" />
                            </div>
                            <div className="verify-text1">
                                I am 18 years of age or older
                        </div>
                        </div>
                        <div className="signin-space"></div>
                        <div className="verification-item2">
                            <div className="checkimage2" key="verifyPolicy" onClick={this.clickedVerify("policy")}>
                                <img src={verifyPolicyImg} alt="" />
                            </div>
                            <div className="verify-text2">
                                I agree to the Terms & Conditions, Privacy Poicy, and Copyright Policy
                        </div>
                        </div>
                    </div>

                    <CertBlock />

                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,  //Lesson 27. make auth.uid available to component to check if it exists in order to implement route guards.
        state
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
